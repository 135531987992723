// stylelint-disable declaration-no-important

// Width and height

@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    .#{$abbrev}-#{$size} { #{$prop}: $length !important; }
  }
}

.mw-25 { max-width: 25% !important; }
.mw-50 { max-width: 50% !important; }
.mw-75 { max-width: 75% !important; }
.mw-100 { max-width: 100% !important; }

.mh-25 { max-height: 25% !important; }
.mh-50 { max-height: 50% !important; }
.mh-75 { max-height: 75% !important; }
.mh-100 { max-height: 100% !important; }

@include media-breakpoint-only(xs) {
  .xs-mw-25 { max-width: 25% !important; }
  .xs-mw-50 { max-width: 50% !important; }
  .xs-mw-75 { max-width: 75% !important; }
  .xs-mw-100 { max-width: 100% !important; }
}
@include media-breakpoint-only(sm) {
  .sm-mw-25 { max-width: 25% !important; }
  .sm-mw-50 { max-width: 50% !important; }
  .sm-mw-75 { max-width: 75% !important; }
  .sm-mw-100 { max-width: 100% !important; }
}
@include media-breakpoint-only(md) {
  .md-mw-25 { max-width: 25% !important; }
  .md-mw-50 { max-width: 50% !important; }
  .md-mw-75 { max-width: 75% !important; }
  .md-mw-100 { max-width: 100% !important; }
}
@include media-breakpoint-only(lg) {
  .lg-mw-25 { max-width: 25% !important; }
  .lg-mw-50 { max-width: 50% !important; }
  .lg-mw-75 { max-width: 75% !important; }
  .lg-mw-100 { max-width: 100% !important; }
}
@include media-breakpoint-only(xl) {
  .xl-mw-25 { max-width: 25% !important; }
  .xl-mw-50 { max-width: 50% !important; }
  .xl-mw-75 { max-width: 75% !important; }
  .xl-mw-100 { max-width: 100% !important; }
}

